<template>
  <div data-component-name="Modals.DownloadAsset" data-id="downloadAsset">
    <strong>{{ $t("Modals.DownloadAsset.thank-you") }}</strong>
    <p>
      {{ $t("Modals.DownloadAsset.please-download") }}

      <NuxtLink
        :to="computedTo"
        target="_blank"
        data-id="document-downloadAssetShort"
      >
        {{ $t("Modals.DownloadAsset.asset-here") }}
      </NuxtLink>
    </p>

    <Buttons.Link
      :to="localePath('/')"
      :accent="accent"
      target="_blank"
      class="nowrap"
    >
      {{ $t("Visit_Home_Page") }}

      <slot name="icon">
        <SvgIcon name="arrow-right-up" />
      </slot>
    </Buttons.Link>
  </div>
</template>

<script lang="ts" setup>
// components
import SvgIcon from '~/components/SvgIcon.vue';
import Buttons from '~/components/redesign/Buttons';

// composables
import useLocalePdfPath from '~/composables/useLocalePdfPath';
import { useLocalePath } from '#i18n';

// types
import type { BaseButtonProps } from '~/components/redesign/Buttons/Base.types';

const props = withDefaults(
  defineProps<{
    to?: string;
    accent?: BaseButtonProps['accent'];
  }>(),
  {
    accent: 'orange',
  },
);

const localePath = useLocalePath();
const localePdfPath = useLocalePdfPath();

const computedTo = computed(
  () => props.to || localePdfPath('DOCUMENT_3_REASONS'),
);
</script>

<style scoped lang="scss">
@import "$/mixins/components";
@import "$/mixins/typography";
@import "$/mixins/flex";
@import "$/functions/token";

[data-component-name="Modals.DownloadAsset"] {
  @include flex-center-center;
  flex-direction: column;

  min-height: 32rem;

  strong {
    @include subtitle-2;
    margin-bottom: 0.5rem;
  }

  p {
    @include body-3;
    text-align: center;

    margin-bottom: 1.5rem;

    a {
      @include app-link;
      @include link-text-3;
    }
  }
}
</style>
